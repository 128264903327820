import sgLogo from 'assets/images/sg-logo.png';
import '../style.scss';
import StatusSection from './StatusSection';
import RepairStatusProgress from '../RepairStatusProgress';
import Ring from 'components/Ring';
import { REPAIR_STATUSES_MAPPING } from '../../constants';
import {
  mapResult,
  defineRepairProgress,
  getEstimatedCompletionDate,
  processDate,
} from '../../utils';
import DisplayInfo from './DisplayInfo';

const RepairStatusResult = ({ loading = false, result }) => {
  const resultData = result?.details?.details ?? result?.details;
  const mappedFields = mapResult(resultData);

  const sectionContent = mappedFields
    ? Object.keys(mappedFields).map((title, index) => {
        if (title !== 'Progress Status') {
          return (
            <StatusSection
              sectionTitle={title}
              sectionData={mappedFields[title]}
              index={index}
            />
          );
        }
      })
    : '';

  const { progressStatus: repairProgressStatus, isWriteOff } = mappedFields
    ? defineRepairProgress(mappedFields)
    : '';

  const fieldDate = !isWriteOff
    ? processDate(
        getEstimatedCompletionDate(
          REPAIR_STATUSES_MAPPING[repairProgressStatus],
          resultData
        )
      )
    : '';

  const progressInfo = isWriteOff
    ? 'Repairs to your vehicle are awaiting authority. Please contact us.'
    : `${repairProgressStatus}:`;

  if (loading) return <Ring />;

  return (
    <div className="repair-status-holder">
      <div className="repair-status">
        <div className="repair-status-brand">
          <img
            className="alignnone  wp-image-9652"
            src={sgLogo}
            // src="https://www.sandgresponse.co.uk/wp-content/uploads/2018/04/SG-logo-horizontal-grey-1-300x130.png"
            alt="S&G Response"
            width="260"
            height="79"
          />
        </div>

        <div className="repair-status-statuses">
          <RepairStatusProgress repairProgressStatus={repairProgressStatus} />
        </div>

        {/* TODO: Move to separate component */}
        {/* <div className="repair-status-date">
          <Icons iconName="calendar" fill="black" width={42} height={42} />
          <div className="repair-status-date-content">
            <div className="repair-status-date-content-title">
              {progressInfo}
            </div>
            <div className="repair-status-date-content-value">
              {esimatedCompletionDate}
            </div>
          </div>
        </div> */}
        <DisplayInfo progressInfo={progressInfo} fieldDate={fieldDate} />

        {sectionContent}
      </div>
    </div>
  );
};

export default RepairStatusResult;
