import Icons from 'common/Icons';
import '../style.scss';

const DisplayInfo = ({ progressInfo = '', fieldDate = '' }) => {
  return (
    <div className="repair-status-date">
      <Icons iconName="calendar" fill="black" width={42} height={42} />
      <div className="repair-status-date-content">
        <div className="repair-status-date-content-title">{progressInfo}</div>
        <div className="repair-status-date-content-value">{fieldDate}</div>
      </div>
    </div>
  );
};

export default DisplayInfo;
