import { useState, useCallback } from 'react';
import axios from 'axios';
import Ring from 'components/Ring';
import { STATUSES } from 'utils/constants';
import RepairStatusResult from './components/RepairStatusResult';
import RepairStatusLogin from './components/RepairStatusLogin';
import {
  constructRepairStatusUrl,
  findNewest,
  isResultRelevant,
  validateDate,
  validateLicensePlate,
} from './utils';
import {
  incidentDateError,
  LICENSE_PLATE_LENGTH,
  notFoundError,
  vehicleRegistrationError,
} from './constants';

const RepairStatusPage = () => {
  const [licensePlateValid, setLicensePlateValid] = useState(false);
  // eslint-disable-next-line
  const [incidentDateValid, setIncidentDateValid] = useState(false);
  const [license, setLicense] = useState('');
  const [date, setDate] = useState('');
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  const handleClaimsError = (claimsError = notFoundError) => {
    setLicense('');
    setDate('');
    setLicensePlateValid(false);
    setIncidentDateValid(false);
    setError(claimsError);
    setIsAuthenticated(false);
    setLoading(false);
  };

  const handleContinue = () => {
    if (!license) {
      setError(vehicleRegistrationError);
      return;
    }

    const isLicensePlateValid = validateLicensePlate(license);

    if (license && !licensePlateValid && !isLicensePlateValid && !error) {
      return setError(vehicleRegistrationError);
    }

    if (license && !isLicensePlateValid && error && !licensePlateValid) {
      setError(null);
      return setLicensePlateValid(true);
    }

    if (isLicensePlateValid && license && !licensePlateValid)
      return setLicensePlateValid(true);

    if (license && !date)
      // if (licensePlateValid && license && !date)
      return setError(incidentDateError);

    const isDateValid = validateDate(date);

    if (!isDateValid) return setError(incidentDateError);

    if (date && isDateValid) {
      setIncidentDateValid(true);
      if (license && date) {
        setLoading(true);
        axios
          .get(constructRepairStatusUrl(license, date))
          .then(({ data }) => {
            const claims = data?.claims;
            if (claims?.length) {
              const openedClaims = claims.filter(
                (c) => c.status === STATUSES.open
              );
              const newestClaim = openedClaims
                ? findNewest(openedClaims)
                : null;

              if (!newestClaim || !openedClaims) {
                handleClaimsError(notFoundError);
              }

              const noRelevantData = isResultRelevant(newestClaim);

              if (!noRelevantData) {
                return handleClaimsError(notFoundError);
              }

              setResult(newestClaim);
              setIsAuthenticated(true);
              setLoading(false);
            } else {
              handleClaimsError(notFoundError);
            }
          })
          .catch(() => {
            setError(notFoundError);
            setLoading(false);
          });
      }

      if (!isAuthenticated && result) {
        setIsAuthenticated(true);
      }
    }
  };

  const handleLicense = useCallback(
    (e) => {
      const val = e.target?.value?.toUpperCase();

      if (error) {
        setError(null);
      }

      const processedLicense =
        val?.length > LICENSE_PLATE_LENGTH ? val.slice(0, -1) : val;
      setLicense(processedLicense);
    },
    [error, setError]
  );

  const handleDate = useCallback(
    (e) => {
      if (error) {
        setError(null);
      }

      const date = e.target.value;
      setDate(date);
    },
    [setDate, error]
  );

  const repairLoginProps = {
    handleDate,
    handleLicense,
    license,
    licensePlateValid,
    handleContinue,
    error,
  };

  return isAuthenticated && license && date && result ? (
    <RepairStatusResult result={result} />
  ) : (
    <>
      {loading && <Ring withScreenBlocker />}
      <RepairStatusLogin {...repairLoginProps} />
    </>
  );
};

export default RepairStatusPage;
