import { currentDate, MIN_DATE } from 'pages/RepairStatus/constants';

const RepairStatusIncidentDate = ({ handleDate }) => (
  <div className="repair-status-login-date">
    <p>Please enter the date of the incident</p>
    <input
      name="date"
      type="date"
      onChange={handleDate}
      className="repair-status-login-date-input"
      // value={value}
      placeholder="Choose date"
      max={currentDate}
      min={MIN_DATE}
    />
  </div>
);

export default RepairStatusIncidentDate;
