import './style.scss';

const Ring = ({ withScreenBlocker }) =>
  withScreenBlocker ? (
    <div className="ring-loader">
      <div className="lds-dual-ring" />
    </div>
  ) : (
    <div className="lds-dual-ring" />
  );

export default Ring;
