import Icons from 'common/Icons';
// import { useRepairStatus } from 'contexts/RepairStatusContext';

const ContinueButton = ({
  text = 'Continue',
  handleClick = () => {},
  loading = false,
}) => {
  // const { loading, handleContinue } = useRepairStatus();

  const btnContent = loading ? (
    'Loading...'
  ) : (
    <>
      {text}
      <Icons iconName="rightArrow3" fill="#ffffff" />
    </>
  );
  return (
    <button
      className="repair-status-login-continue"
      type="button"
      onClick={handleClick}
    >
      {btnContent}
    </button>
  );
};

export default ContinueButton;
