import RepairStatusLicensePlate from './RepairStatusLicensePlate';
import RepairStatusIncidentDate from './RepairStatusIncidentDate';
import ContinueButton from './ContinueButton';

import sgLogo from 'assets/images/sg-logo.png';

import '../style.scss';

const RepairStatusLogin = ({
  handleDate,
  handleLicense,
  license,
  licensePlateValid,
  handleContinue,
  error,
}) => {
  const licensePlateProps = { handleLicense, license };

  const incidentDateProps = { handleDate };

  const showContent = !licensePlateValid ? (
    <RepairStatusLicensePlate {...licensePlateProps} />
  ) : (
    <RepairStatusIncidentDate {...incidentDateProps} />
  );

  const errorMessage = error ? (
    <div className={`repair-status-login-error`}>{error}</div>
  ) : null;

  return (
    <div className="repair-status-login-holder">
      <div className="repair-status-login">
        <div className="repair-status-login-brand">
          <img
            className="alignnone  wp-image-9652"
            src={sgLogo}
            // src="https://www.sandgresponse.co.uk/wp-content/uploads/2018/04/SG-logo-horizontal-grey-1-300x130.png"
            alt="S&G Response"
            width="155"
            height="67"
            // width="155"
            // height="67"
          />
        </div>
        <div className="repair-status-login-content">
          <h2 className="repair-status-login-title">Vehicle Repair Status</h2>
          {showContent}
        </div>
        {errorMessage}
        <ContinueButton text="Continue" handleClick={handleContinue} />
      </div>
    </div>
  );
};

export default RepairStatusLogin;
