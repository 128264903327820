import '../style.scss';

const StatusSection = ({ sectionTitle = '', sectionData = {}, index = 1 }) => {
  const sectionContent = Object.keys(sectionData).map((section, i) => {
    const outlinedStyle =
      section === 'Repairer email contact' ||
      section === 'Repairer telephone number'
        ? 'repair-status-section-content-value-outlined'
        : '';

    return (
      <div className="repair-status-section-content" key={i + 3}>
        <div className="repair-status-section-content-key" key={i + 4}>
          {section}
        </div>
        <div
          className={`repair-status-section-content-value ${outlinedStyle}`}
          key={i + 5}
        >
          {sectionData[section]}
        </div>
      </div>
    );
  });

  return (
    <div className="repair-status-section" key={index}>
      <div className="repair-status-section-title" key={index + 1}>
        {' '}
        {sectionTitle}
      </div>
      <div className="repair-status-section-divider" key={index + 2} />
      {sectionContent}
    </div>
  );
};

export default StatusSection;
