import React from 'react';
import image from 'assets/images/gb.png';
// import { useRepairStatus } from 'contexts/RepairStatusContext';

const RepairStatusLicensePlate = ({
  handleLicense = () => {},
  license = '',
}) => {
  // const { handleLicense, license } = useRepairStatus();

  return (
    <div className="repair-status-login-license">
      <p>Please enter your Vehicle Registration Number</p>

      <div className="repair-status-login-license-plate">
        <div className="repair-status-login-license-plate-country">
          <img src={image} alt="Great Britain flag" width={28} height={28} />
          <span className="repair-status-login-license-plate-country-code">
            GB
          </span>
        </div>
        <input
          name="license"
          type="text"
          onChange={handleLicense}
          value={license}
          className="repair-status-login-license-plate-input"
          placeholder="AK70SMR"
        />
      </div>
    </div>
  );
};

export default RepairStatusLicensePlate;
